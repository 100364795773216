<template>
  <div class="pa-5 d-flex flex-column">
    <header>
      <h3>{{ $t("checkout.alternativeProduct.title") }}</h3>
      <p class="my-3">
        {{ $t("checkout.alternativeProduct.description") }}
      </p>
    </header>
    <!-- <div v-if="product" class="my-3 d-flex flex-column">
      <h4>{{ $t("checkout.alternativeProduct.product") }}</h4>
      <v-card outlined class="pa-4">
        <div class="d-flex flex-column">
          <v-img
            :src="product.mediaURL"
            class="mx-auto"
            width="85px"
            height="85px"
            max-height="85px"
            max-width="85px"
          />
          <template>
            <h6>{{ product.name }}</h6>
            <span>{{ product.shortDescr }}</span>

            <span class="descr" v-if="product.description">
              {{ product.description }}</span
            >
            <span
              v-if="
                product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                  product.priceUmDisplay
              "
              class="weight-unit"
            >
              {{ $n(product.priceUmDisplay, "currency") }}/{{
                product.weightUnitDisplay
              }}
            </span>
            <span
              v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
              class="weight-unit descr"
            >
              {{ minQuantity }} {{ unit }}
            </span>
          </template>
          <div class="my-3">
            <ProductPrice :product="product" />
          </div>
        </div>
      </v-card>
    </div> -->
    <div class="d-flex flex-column my-3" v-if="products?.length > 0">
      <h3>{{ $t("checkout.alternativeProduct.alternatives") }}</h3>
      <swiper
        :key="swiperKey"
        class="w-100 my-3"
        :options="swiperOption"
        ref="swiperRef"
      >
        <swiper-slide
          class="fill-height"
          v-for="product in products"
          :key="product.productId"
        >
          <v-card outlined class="product-alternative fill-height pa-4">
            <!-- <div class="col-8"> -->
            <div class="d-flex flex-column">
              <v-img
                :src="product.mediaURL"
                class="mx-auto"
                width="85px"
                height="85px"
                max-height="85px"
                max-width="85px"
              />
              <template>
                <h6 class="product-alternative__title">{{ product.name }}</h6>
                <span>{{ product.shortDescr }}</span>

                <span class="descr" v-if="product.description">
                  {{ product.description }}</span
                >
                <span
                  v-if="
                    product.productInfos?.TIPOLOGIA != 'Sfuso' &&
                      product.priceUmDisplay
                  "
                  class="weight-unit"
                >
                  {{ $n(product.priceUmDisplay, "currency") }}/{{
                    product.weightUnitDisplay
                  }}
                </span>
                <span
                  v-else-if="product.productInfos?.TIPOLOGIA == 'Sfuso'"
                  class="weight-unit descr"
                >
                  <!-- <span v-else class="weight-unit descr"> -->
                  {{ minQuantity }} {{ unit }}
                </span>
              </template>
              <div class="my-3">
                <ProductPrice :product="product" />
              </div>
            </div>
          </v-card>
        </swiper-slide>
      </swiper>
    </div>
    <swiper-paginator
      :showBullets="true"
      :paginationClass="paginationClass"
      :length="products?.length"
    />
  </div>
</template>
<style scoped lang="scss">
.v-card {
  max-width: fit-content;
}

.swiper-slide .v-card {
  max-width: 100%;
}
.product-alternative__title {
  min-height: 50px;
}
</style>
<script>
import productMixin from "~/mixins/product";
import ProductService from "@/commons/service/productService";
import ProductPrice from "@/components/product/ProductPriceLeaflet.vue";
import SwiperPaginator from "../SwiperPaginator.vue";
// import ProductListSlider from "@/components/product/ProductListSlider.vue";
export default {
  name: "ProductAlternative",
  components: { ProductPrice, SwiperPaginator },
  //   , ProductListSlider
  mixins: [productMixin],
  props: { product: { type: Object, required: true } },
  data() {
    return {
      error: null,
      swiperKey: Date.now(),
      paginationClass: "product-alternative",
      products: null,
      response: {},
      swiperOption: {
        slidesPerGroup: 1,
        spaceBetween: 16,
        watchOverflow: true,
        pagination: {
          el: "#pagination-product-alternative",
          clickable: true
        },
        // navigation: {
        //   prevEl: `#product-list-slider-prev-product-alternative-pagination`,
        //   nextEl: `#product-list-slider-next-product-alternative-pagination`
        // },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 16
          },
          600: {
            slidesPerView: 2
          },
          960: {
            slidesPerView: 3
          }
          // 1280: {
          //   slidesPerView: 3
          // },
          // 1590: {
          //   slidesPerView: 3
          // }
        }
      }
    };
  },
  methods: {
    // destroySwiper() {
    //   if (this.$refs.swiperRef && this.$refs.swiperRef.swiper) {
    //     this.$refs.swiperRef.swiper.destroy(true, true);
    //   }
    // },
    async getProductAlternativeDetails() {
      let res = await ProductService.getProductAlternativeDetails(
        true,
        this.product.productId
      );
      if (res?.products) {
        this.products = res.products;
      }
    }
  },
  async created() {
    await this.getProductAlternativeDetails();
  },
  beforeDestroy() {
    //  this.destroySwiper();
  },
  mounted() {
    this.swiperKey = Date.now();
    // this.$on("closed", () => {
    //   this.destroySwiper();
    // });
  }
};
</script>
